.tituloCursivo {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Dancing Script", cursive !important ;
  color: aliceblue;
  text-align: center
}

.fondo {
  /* background-image:url('/images/plato-comida.jpg') !important; */
  /* background-color:#8ed6ff !important; */
  flex-grow: 5;
  padding: 10px;
}

.listItem:hover {
  background-color: #0083ad !important;
  cursor: pointer;
}

main {
  max-width: "" !important;
}
.catSelected {
  background-color: #0083ad !important;
  color: white !important;
  border-radius: 15px;
}

.botonAddPink {
  background-color: #089e6c !important;
}
.iconDrop :hover {
  cursor: move;
}

.lista_scroll::-webkit-scrollbar:vertical {
  width:5px;
}
.lista_scroll::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 



.lista_scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.lista_scroll::-webkit-scrollbar-track {
  border-radius: 10px;  
}
.makeStyles-fondo-1::-webkit-scrollbar {
  display: none;
} 

@media print {
  * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

 
}

.textboxTelefono {
  -moz-appearance: textfield !important;
}

a.list-item {
 text-decoration: none !important;
 
}

.listItem span {
  color:white !important;
}

a.active > div{
    background-color: #4e4e4e ;
}


a.active.noactive>div {
   background-color:transparent;
}