.tituloCursivo {
    font-size: 10px;

}
tituloCursivo_login > b {
    font-size: 20px;
}

@media only screen and (max-width: 960px) {

    .divInfoApp_1{

        display: none !important;
        
    }

    .divInfoApp_2{

    display: "" !important;
    background-color:#313131 !important;
    color: white !important;
    height: 20% !important;
    width: 100% !important;
    padding: 15px  !important;
    }
       
    .makeStyles-paper_log-1{
	display: flex;
	align-items: center;
	padding-top: 70px !important;
	flex-direction: column;
    }

    .buttonDashboar:hover{

        background-color: aqua;

    }

.tituloCursivo_login {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
    font-family: 'Dancing Script', cursive !important ;
    font-size: 1.4rem !important;
    text-align: center;
    
    
}
.tituloCursivo_login > b {
    font-size: 10px;
}
.subtituloCursivo_login{
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
    font-family: 'Righteous', cursive !important ;
    font-size: 1rem !important;
    text-align: center;
    
}

}
@media only screen and (min-width: 961px ) and (max-width: 1384px){

    .divInfoApp_1{
        /* background-image:url('/images/plato-comida.jpg') !important; */
        background-color: #313131 !important;
        color: white !important;
        height: 100vh !important;
        width: 100% !important;
    }

    .divInfoApp_2{

        display: none !important;
        background-color: #313131 !important;
        color: white !important;
        height: 100% !important;
        width: 100% !important;
        padding-top: 15px;
    }
    h3 {
    
        font-size: 2rem !important;
    }
    h5 {
    
        font-size: 1.1rem !important;
    }
    .makeStyles-paper_log-1 {
        display: flex;
        align-items: center;
     
        flex-direction: column;
    }
    
    .tituloCursivo {
     
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Dancing Script', cursive !important ;
        text-align: center;
        
    }
    .tituloCursivo>b {
        font-size: 25px;
    }
    .subtituloCursivo{
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Righteous', cursive !important ;
        text-align: center;
        
    }
    
    }
@media only screen and (min-width: 1385px) {

    .divInfoApp_2{

        display: none !important;
        background-color: #313131 !important;
        color: white !important;
        /* height: 20% !important; */
        width: 100% !important;
        padding-top: 15px;
    }
    .tituloCursivo {
    
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Dancing Script', cursive !important ;
        text-align: center;

        
    }
.tituloCursivo>b {
    font-size: 25px;
}
    .subtituloCursivo{
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Righteous', cursive !important ;
        text-align: center;
        
        
    }

    .divInfoApp_1{
        /* background-image:url('/images/plato-comida.jpg') !important; */
        background-color: #313131 !important;
        color: white !important;
        height: 100vh !important;
        width: 100% !important;
    }

}


    