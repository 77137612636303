.divInfoApp {

    /* display: none !important; */
    background-color: #313131 !important;
    color: white !important;
    height: 20% !important;
    width: 100% !important;
    /* padding-top: 15px; */
}
@media only screen and (max-width: 960px) {

.divInfoApp{

    /* display: none !important; */
    background-color: #313131 !important;
    color: white !important;
    height: 20% !important;
    width: 100% !important;
    /* padding-top: 15px; */
}
h3 {

    font-size: 1.6rem !important;
}
h5 {

    font-size: 1.1rem !important;
}
.makeStyles-paper-1 {
	display: flex;
	align-items: center;
	/* padding-top: 30px !important; */
	flex-direction: column;
}

.tituloCursivo {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
    font-family: 'Dancing Script', cursive !important ;
    text-align: center
    
}

.tituloCursivo > b{
    font-size: 20px;
}
.subtituloCursivo{
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
    font-family: 'Righteous', cursive !important ;
     text-align: center
    
}

}
@media only screen and (min-width: 961px ) and (max-width: 1200px){

    .divInfoApp{
    
       
        color: white !important;
        /* height: 20% !important; */
        width: 100% !important;
        /* padding-top: 15px; */
    }
    h3 {
    
        font-size: 2.3rem !important;
    }
    h5 {
    
        font-size: 1.1rem !important;
    }
    .makeStyles-paper-1 {
        display: flex;
        align-items: center;
        /* padding-top: 30px !important; */
        flex-direction: column;
    }
    
    .tituloCursivo {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Dancing Script', cursive !important ;
        
        
    }
    .tituloCursivo > b {
        font-size: 30px;
    }
    .subtituloCursivo{
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: 'Righteous', cursive !important ;
        
        
    }
    
    }
@media only screen and (min-width: 961px) {
.tituloCursivo {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
    font-family: 'Dancing Script', cursive !important ;
    
    
}
.tituloCursivo>b {
    font-size: 25px;
}
.subtituloCursivo{
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
    font-family: 'Righteous', cursive !important ;
    
    
}

.divInfoApp{
    /* background-image:url('/images/plato-comida.jpg') !important; */
   
    color: white !important;
    height: 100vh !important;
    width: 100% !important;
}

}