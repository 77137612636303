.tituloDash {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 2rem !important;
  line-height: 42px !important;
  letter-spacing: 0.15px !important;
  padding-left: 5%;

  /* White — High Emphasis */

  color: #35c0ed;
  mix-blend-mode: normal;
  text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.25) !important;
}

.subtituloDash {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem !important;
  line-height: 20px;
  /* or 83% */
  padding-left: 5.5%;
  letter-spacing: 0.3px;

  /* White — Medium Emphasis */

  color: rgb(125, 125, 131);
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 10px;
}

.cards {
  min-width: 275px !important;
  max-width: 380px !important;
  border-radius: 30px !important;
  height: 140px;
  margin: 5px;
  margin-left: 1px;
  -webkit-box-shadow: 8px 9px 8px 1px rgba(115, 115, 115, 0.51) !important;
  -moz-box-shadow: 8px 9px 8px 1px rgba(115, 115, 115, 0.51) !important;
  box-shadow: 3px 8px 5px 1px rgba(24, 15, 15, 0.3) !important;
  background-color: #d1e7ff;
}

.cards:hover {
  cursor: pointer;
}

.cards:active {
  background-color: #085f7c !important;
}
.AllCard {
  transform: translateY(0px);
  transition: 0.2s;
  /* transform:scale(0px,0px) !important; */
}
.AllCard:hover {
  transform: translateY(-10px);
  transition: 0.2s;
}
.AllCard:active {
  /* transform: translateY(-20px); */
  transform: scale(98%, 98%) !important;
  -webkit-transform: scale(98%, 98%) !important;
  -ms-transform: scale(98%, 98%) !important;
  -moz-transform: scale(98%, 98%) !important;
  -o-transform: scale(98%, 98%) !important;
  transition: 0.2s;
}
button:active {
  transform: scale(95%, 95%) !important;
  -webkit-transform: scale(95%, 95%) !important;
  -ms-transform: scale(95%, 95%) !important;
  -moz-transform: scale(95%, 95%) !important;
  -o-transform: scale(95%, 95%) !important;
  transition: 0.2s;
}
